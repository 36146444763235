<template>
	<div id="login_init">
	</div>
</template>

<script type="text/javascript">
	import pcj from '@/assets/js/aes_encrypt.js'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'SignInit',
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 获取当前路由含带的参数信息
			const thisRouteParam = ctx.$route.query

			ctx.$loading({
				lock: true,
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.8)'
			})

			const closeWin = (times = 2000) => {
				setTimeout(() => {
					window.opener = null;
					window.open('', '_self');
					window.location.href = "about:blank";
					window.close();

					document.addEventListener('WeixinJSBridgeReady', function() {
						WeixinJSBridge.call('closeWindow')
					}, false);
					WeixinJSBridge.call('closeWindow')
				}, times)
			}

			if (ctx.$pubFun_.isWeiXin()) {
				if (ctx.$pubFun_.isMobile()) {
					ctx.$message.error('请通过PC端企业微信内置浏览器打开')
					closeWin()
				} else {
					// 获取企业微信JSSDK配置文件
					ctx.$request_nl_.post(ctx.$api_.state.Home.Login.get_qywork_jssdk.url, {
							auth_path: ctx.$route.fullPath,
							source: 'mess'
						})
						.then((respon) => {
							if (respon.Code === ctx.$code_.state.success) {
								wx.config({
									beta: true,
									debug: false,
									appId: respon.Data.appId,
									timestamp: respon.Data.timestamp,
									nonceStr: respon.Data.nonceStr,
									signature: respon.Data.signature,
									jsApiList: ['openDefaultBrowser']
								})
								wx.ready(function() {
									wx.invoke('openDefaultBrowser', {
										'url': respon.Data.auth_url,
									}, function(res) {
										if (res.err_msg == "openDefaultBrowser:ok") {
											ctx.$message({
												message: '打开默认浏览器成功，即将进行授权登录',
												type: 'success'
											})
										} else {
											ctx.$message.error('打开默认浏览器失败，请退出后重试')
										}

										closeWin()
									})
								})
							} else {
								ctx.$message.error(respon.Message)
								closeWin()
							}
						})
				}
			} else {
				const code = thisRouteParam.code ? thisRouteParam.code : ctx.$pubFun_.getQueryString('code')
				const state = thisRouteParam.state ? thisRouteParam.state : ctx.$pubFun_.getQueryString('state')
				if (code === null) {
					ctx.$notify({
						title: '温馨提示',
						message: '登录错误！',
						type: 'warning'
					})

					ctx.$router.push({
						name: 'SignIn'
					})
				} else {
					//提取参数信息
					var params = ctx.$route.fullPath.substring(ctx.$route.fullPath.indexOf('encryption=') + (
						'encryption=').length, ctx.$route.fullPath.length)

					// 数据aes解密
					const params_aes = pcj.aesDecryptToDirect(params)

					if (params_aes.length > 0) {
						// 将json字符串转换成json数组
						const params_json = JSON.parse(params_aes)

						// 存放路径
						if (typeof params_json.path != 'undefined') {
							ctx.$api_.commit('setCurrentPath', JSON.stringify({
								path: params_json.path,
								query: params_json.query
							}))
						}
					}

					window.location.href = '/#/QrcodeAuth?code=' + code + '&state=' + state + '&source=mess'
				}
			}
		}
	})
</script>
